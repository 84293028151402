/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, {useContext, useState} from 'react';
import '@style/main.scss';
import Header from './header';
import Footer from './footer';
import AccessPopup from './access-popup';
import CountriesModal from './countries-modal';
import CookieManagement from './cookie-management';
import EdmusContext from './application.provider';
import NewsletterModal from './newsletter-modal';

interface PropsType {
  children: React.ReactNode;
}

const Layout = ({children}: PropsType) => {
  const contextValue = useContext(EdmusContext);

  const openDialog = () => contextValue.openDialog();
  const closeDialog = () => contextValue.closeDialog();

  if (contextValue.adultAgree !== undefined && !contextValue.adultAgree)
    return (
      <EdmusContext.Consumer>
        {state => (
          <>
            <AccessPopup
              changeAdultAgreement={contextValue.changeAdultAgreement}
            />
            <CookieManagement
              cookieAgreement={state.cookieAgree}
              changeCookieAgreement={state.changeCookieAgreement}
            />
          </>
        )}
      </EdmusContext.Consumer>
    );

  return (
    <EdmusContext.Consumer>
      {state => (
        <>
          <Header openStores={openDialog} />
          <div>
            {!contextValue.dialogOpened && (
              <a
                onClick={e => {
                  e.preventDefault();
                  openDialog();
                }}
                href='/stores'
                target='_blank'
                className='shop-button'
              />
            )}
            <main>{children}</main>
          </div>
          <Footer />
          <CookieManagement
            cookieAgreement={state.cookieAgree}
            changeCookieAgreement={state.changeCookieAgreement}
          />
          <NewsletterModal
            opened={contextValue.dialogOpened}
            onClose={closeDialog}
          />
          {/* <CountriesModal
            opened={contextValue.dialogOpened}
            onClose={closeDialog}
          /> */}
          {/* <iframe
            width='540'
            height='305'
            src='https://9720fb4e.sibforms.com/serve/MUIEAO7Uqttl5buKaMVC_f99iXy1-HiKMoHe39brRZq3ztrckjgMS7XVnmr0bZFAYY4_XyNTfvdyRp5fd6xQpUULr6HloGbUw8941ZyoUAPkrpdkWS7zhH_D3HLzCi44cF9x5Tjmu8U2ABwzbAGdXbLXNCoov-otaxr-5CSgWwSu0Yo0WADCx-2pzA0Zyt8KdcLkn2Bci8_zb2Hx'
            frameBorder='0'
            scrolling='auto'
            allowFullScreen
            style={{
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '100%',
            }}
          ></iframe> */}
        </>
      )}
    </EdmusContext.Consumer>
  );
};

export default Layout;
