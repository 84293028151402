import React from 'react';
import Logo from './../static/img/logo.svg';
import useLabels from '@hooks/useLabels';

const AccessPopup = (props: any) => {
  const { labels } = useLabels();
  const reroot = () => {
    window.location.replace('https://www.google.com/');
  };

  return (
    <div>
      <div className='entrance-popup text-center container'>
        <div className='columns full-height align-items'>
          <div className='col-12 logo'>
            <img
              className='img-responsive'
              src={Logo}
              alt='Château Edmus Logo'
            />
          </div>
          <div className='col-8 col-mx-auto welcome '>
            {labels.ACCESS_POPUP_WELCOME}
          </div>
          <div className='col-8 col-mx-auto  age hide-sm'>
            {labels.ACCESS_POPUP_AGE_CONFIRMATION}
          </div>
          <div className='col-8 col-mx-auto  age'>
            <div className='  show-sm inner-space-bottom-10'>
              {labels.ACCESS_POPUP_AGE_CONFIRMATION}
            </div>
            <button
              type='button'
              onClick={() => props.changeAdultAgreement(true)}
            >
              {labels.COMMON_YES}
            </button>
            <button type='button' onClick={reroot}>
              {labels.COMMON_NO}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessPopup;
