import {graphql, useStaticQuery, Link} from 'gatsby';
import React, {useState, useContext} from 'react';
import {navigate} from 'gatsby';
import LogoWhite from './../static/img/logo-white.svg';
import NavLink from './nav-link';
import {LinkTo} from '../models/LinkTo.type';
import EdmusContext from './application.provider';
import useLabels from '@hooks/useLabels';

interface PropsType {
  openStores: any;
}

const Header = (props: any) => {
  const {prismic} = useStaticQuery(
    graphql`
      query {
        prismic {
          allMenus {
            edges {
              node {
                links {
                  name1
                  externLink {
                    ... on PRISMIC__ExternalLink {
                      url
                    }
                  }
                  internLink
                }
                _meta {
                  lang
                  type
                  uid
                }
              }
            }
          }
        }
      }
    `
  );
  const {labels} = useLabels();
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const {language} = useContext(EdmusContext);
  const {allMenus} = prismic;
  const header = allMenus.edges.find(
    (headers: any) => headers.node._meta.lang.slice(0, 2) === language
  );

  if (!header) return null;
  const goToHome = () => {
    navigate('/');
  };

  const updateMenu = () => {
    setIsMenuOpened(!isMenuOpened);
  };

  const openStores = () => {
    setIsMenuOpened(!isMenuOpened);
    props.openStores();
  };

  return (
    <header className='header'>
      <div className='container columns header-logo-container'>
        <div className='column col-6 col-sm-5 col-md-4 col-lg-4 col-xl-5'>
          <img
            className={'header-logo lazyload img-responsive'}
            data-src={LogoWhite}
            onClick={() => goToHome()}
          />
        </div>

        <div className='header-nav column  col-6 col-lg-8 col-xl-7 hide-md'>
          <div className='nav-menu'>
            <ul className='columns no-bullet-list'>
              {header.node.links.map((link: LinkTo, key: number) => (
                <li className='nav-item' key={key}>
                  <NavLink
                    name={link.name1[0].text}
                    internLink={
                      link.internLink ? link.internLink[0].text : undefined
                    }
                    externLink={link.externLink?.url}
                    action={
                      link.internLink && link.internLink[0].text === '/stores'
                        ? props.openStores
                        : null
                    }
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className='column col-6 col-sm-7 col-md-8 show-md text-right'>
          <button className='btn-menu' onClick={() => updateMenu()}>
            <i className='icon icon-menu' />
          </button>
        </div>
      </div>

      <div style={{display: isMenuOpened ? 'block' : 'none'}}>
        <div className='menu'>
          <ul className=' menu-close'>
            <li className='column '>
              <button className='btn-menu' onClick={() => updateMenu()}>
                <i className='icon icon-cross iconX2' />
              </button>
            </li>
          </ul>
          <ul className='menu-logo'>
            <li>
              <img
                className={'lazyload menu-logo'}
                data-src={LogoWhite}
                onClick={() => goToHome()}
              />
            </li>
          </ul>
          <ul className='menu-items'>
            {header.node.links.map((link: LinkTo, key: number) => (
              <li className='column nav-item' key={key}>
                <NavLink
                  name={link.name1[0].text}
                  internLink={
                    link.internLink ? link.internLink[0].text : undefined
                  }
                  externLink={link.externLink?.url}
                  action={
                    link.internLink && link.internLink[0].text === '/stores'
                      ? openStores
                      : null
                  }
                />
              </li>
            ))}
          </ul>
          <ul>
            <li className='sub-menu'>
              <Link to='/newsletter'>{labels.COMMON_NEWSLETTER}</Link>
              <Link to='/contact'>{labels.COMMON_CONTACT}</Link>
              {/* <span>Langues</span>  TODO ad select language*/}
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
};

export default Header;
