export default {
  COMMON_YES: 'YES',
  COMMON_NO: 'NO',
  COMMON_CONTACT: 'Contact',
  COMMON_NEWSLETTER: 'Newsletter',
  COMMON_EDMUS_COPYRIGHT: '© Château Edmus 2020',

  KNOW_MORE: 'Learn more',
  ACTUALLY_EDMUS: 'EDMUS IN REAL TIME',
  INSTAGRAM: 'EDMUS on Intagram',
  PREVIOUS_DAY: 'Go back a day',

  ACCESS_POPUP_WELCOME:
    'Welcome to Château Edmus, the Saint-Emilion Grand Cru where nature comes first',
  ACCESS_POPUP_AGE_CONFIRMATION:
    'ARE YOU OF LEGAL DRINKING AGE IN YOUR COUNTRY OF RESIDENCE?',

  COOKIE_INFO:
    'We use cookies to enhance your browsing experience and improve the performance of our website.',
  COOKIE_MORE_INFO: 'More info',
  COOKIE_AGREE: 'I agree',
  COOKIE_DISAGREE: "I don't agree",

  CONTACT_WINE_ANGELS: 'Become a Wine Angel',

  EDMUS_CLUB: 'Join-us to receive a great deal once a year and stay in touch.',
  EDMUS_CLUB_LINE_1: '',
  EDMUS_CLUB_CONDITION: '',

  WINE_ANGELS_BECOMING: 'Become a Wine Angel',
  WINE_ANGELS_BECOMING_PROCESS:
    'Becoming a Wine Angel involves a strict selection procedure to assess:',
  WINE_ANGELS_POSTULATE: 'Apply Here',

  WINE: 'Wine',
  VINTAGE: 'Vintage',
  VINTAGE_ORDER: 'Place Order',
  VINTAGE_TECHNIC_SHEET: 'Info Sheet',
  VINTAGE_PRESS_KIT: 'Press Kit',

  TIME_AGO_SECONDES: 'seconds',
  TIME_AGO_1_MINUTE: '1 minute',
  TIME_AGO_TIME_THERE_IS_1_MINUTE: 'One minute ago',
  TIME_AGO_MINUTES: 'minutes',
  TIME_AGO_1_HOUR: '1 hour',
  TIME_AGO_THERE_IS_1_HOUR: 'One hour ago',
  TIME_AGO_HOURS: 'hours',
  TIME_AGO_YESTERDAY: 'Yesterday',
  TIME_AGO_TOMORROW: 'Tomorrow',
  TIME_AGO_DAYS: 'days',
  TIME_AGO_NEXT_WEEK: 'Next week',
  TIME_AGO_LAST_WEEK: 'Last week',
  TIME_AGO_WEEKS: 'weeks',
  TIME_AGO_LAST_MONTH: 'Last month',
  TIME_AGO_NEXT_MONTH: 'Next month',
  TIME_AGO_MONTHS: 'months',
  TIME_AGO_LAST_YEAR: 'Last year',
  TIME_AGO_NEXT_YEAR: 'Next year',
  TIME_AGO_YEARS: 'years',
  TIME_AGO_LAST_CENTUARY: 'Last century',
  TIME_AGO_NEXT_CENTUARY: 'Next century',
  TIME_AGO_CENTUARIES: 'centuries',
  TIME_AGO_JUST_NOW: 'Just now',
  TIME_AGO_FROM_NOW: 'From now',

  TIMELAPS_ARCHIVES: 'Click here to go back in time in the vines of Edmus',

  WEATHER_TITLE: 'RAISED IN THE VINES',

  WEATHER_TEMPERATURE: 'Temperature',
  WEATHER_RAINFALL: 'Rainfall',
  WEATHER_HUMIDITY: 'Humidity',
  WEATHER_MOISTENING: 'Leaf wetness',

  STORES: 'Stores',
  STORES_PLACEHOLDER: 'Select your country',
  STORE_SELECT: 'Please, select the destination country of your order',
  STORE_CONFIRM: 'Valid',

  STORE_UNAVAILABLE:
    '<p>Château EDMUS wines will soon be available only by allocation.</p><p>In the meantime, participate in our annual private sales by subscribing to our newsletter below.</p>',
  STORE_SUBSCRIBE: 'Subscribe',
};
