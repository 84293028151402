export default {
  COMMON_YES: 'OUI',
  COMMON_NO: 'NON',
  COMMON_CONTACT: 'Contact',
  COMMON_NEWSLETTER: 'Newsletter',
  COMMON_EDMUS_COPYRIGHT: '© Château Edmus, 2020',

  KNOW_MORE: 'En savoir plus',
  ACTUALLY_EDMUS: 'En ce moment à Edmus',
  INSTAGRAM: 'EDMUS sur Intagram',
  PREVIOUS_DAY: " Cliquer ici pour remonter le temps dans les vignes d'edmus",

  ACCESS_POPUP_WELCOME:
    'Bienvenue dans l’univers du Château Edmus, un Saint-Emilion Grand Cru qui renaît au naturel',
  ACCESS_POPUP_AGE_CONFIRMATION:
    'ÊTES-VOUS EN ÂGE DE CONSOMMER DE L’ALCOOL DANS VOTRE PAYS DE RÉSIDENCE ?',

  COOKIE_INFO:
    'Nous utilisons des cookies afin d’améliorer votre expérience utilisateur et la qualité de notre site.',
  COOKIE_MORE_INFO: 'Plus d’infos',
  COOKIE_AGREE: "J'accepte",
  COOKIE_DISAGREE: 'Je refuse',

  CONTACT_WINE_ANGELS: 'Devenir Wine Angel',

  EDMUS_CLUB:
    'Inscrivez-vous ici pour recevoir la newsletter du Château EDMUS et participer à nos ventes privées',
  EDMUS_CLUB_LINE_1:
    'En renseignant votre adresse email, vous acceptez de recevoir régulièrement les dernières actualités du Château EDMUS par courrier électronique.',

  EDMUS_CLUB_CONDITION:
    "Vous pouvez vous désinscrire à tout moment à l'aide des liens de désinscription ou en nous contactant à contact@chateauedmus.com",

  WINE_ANGELS_BECOMING: 'Devenir un Wine Angels',
  WINE_ANGELS_BECOMING_PROCESS:
    'Devenir un Wine Angel passe par un processus de sélection strict où seront évalués :',
  WINE_ANGELS_POSTULATE: 'Postulez ici',

  WINE: 'Le Vin',
  VINTAGE: 'Millésime',
  VINTAGE_ORDER: 'Commander',
  VINTAGE_TECHNIC_SHEET: 'Fiche Technique',
  VINTAGE_PRESS_KIT: 'Dossier de presse',

  TIME_AGO_SECONDES: 'secondes',
  TIME_AGO_1_MINUTE: '1 minute',
  TIME_AGO_TIME_THERE_IS_1_MINUTE: 'Il y a 1 minute',
  TIME_AGO_MINUTES: 'minutes',
  TIME_AGO_1_HOUR: '1 heure',
  TIME_AGO_THERE_IS_1_HOUR: 'Il y a 1 heure',
  TIME_AGO_HOURS: 'heures',
  TIME_AGO_YESTERDAY: 'Hier',
  TIME_AGO_TOMORROW: 'Demain',
  TIME_AGO_DAYS: 'jours',
  TIME_AGO_NEXT_WEEK: 'La semaine prochaine',
  TIME_AGO_LAST_WEEK: 'La semaine dernière',
  TIME_AGO_WEEKS: 'semaines',
  TIME_AGO_LAST_MONTH: 'Le mois dernier',
  TIME_AGO_NEXT_MONTH: 'Le mois prochain',
  TIME_AGO_MONTHS: 'mois',
  TIME_AGO_LAST_YEAR: "L'année dernière",
  TIME_AGO_NEXT_YEAR: "l'année prochaine",
  TIME_AGO_YEARS: 'années',
  TIME_AGO_LAST_CENTUARY: 'Le siècle dernier',
  TIME_AGO_NEXT_CENTUARY: 'Le siècle prochain',
  TIME_AGO_CENTUARIES: 'siècles',
  TIME_AGO_JUST_NOW: "À l'instant",
  TIME_AGO_FROM_NOW: 'À partir de maintenant',

  TIMELAPS_ARCHIVES: 'Archives',

  WEATHER_TITLE: 'RELEVÉ DANS LES VIGNES',

  WEATHER_TEMPERATURE: 'Température',
  WEATHER_RAINFALL: 'Pluviométrie',
  WEATHER_HUMIDITY: 'Hygrométrie',
  WEATHER_MOISTENING: 'Humectation',

  STORES: 'Boutiques',
  STORES_PLACEHOLDER: 'Sélectionner votre pays',
  STORE_SELECT: 'Veuillez sélectionner le pays destinataire de votre commande',
  STORE_CONFIRM: 'Valider',

  STORE_UNAVAILABLE:
    '<p>Les Vins du Château EDMUS seront bientôt uniquement disponibles par allocation.</p><p>En attendant, participer à nos ventes privées annuelles en vous inscrivant à notre newsletter ci-dessous.</p>',
  STORE_SUBSCRIBE: "S'incrire",
};
