import {Link} from 'gatsby';
import React, {Fragment} from 'react';

interface PropsType {
  internLink?: string;
  externLink?: string;
  name: string;
  class?: string;
  action?: any;
}

const NavLink = (props: PropsType) => {
  return (
    <Fragment>
      {props.internLink ? (
        props.action ? (
          <a
            className={props.class || ''}
            href={props.internLink}
            onClick={e => {
              e.preventDefault();
              props.action();
            }}
          >
            {props.name}
          </a>
        ) : (
          <Link
            className={props.class || ''}
            to={props.internLink}
            activeClassName='active'
          >
            {props.name}
          </Link>
        )
      ) : (
        <a
          className={props.class || ''}
          href={props.externLink}
          target='_blank'
        >
          {props.name}
        </a>
      )}
    </Fragment>
  );
};

export default NavLink;
