import React from 'react';
import useLabels from '@hooks/useLabels';

const CookieManagement = (props: any) => {
  const { cookieAgreement, changeCookieAgreement } = props;
  const { labels } = useLabels();
  if (cookieAgreement === 'true' || cookieAgreement === 'false') return null;

  return (
    <div className='container cookie-management'>
      <div className='columns'>
        <div className='col col-8 col-sm-12 mb-1 text-center'>
          {labels.COOKIE_INFO}
          <a className='ml-2' href='/legal-notices'>
            {labels.COOKIE_MORE_INFO}
          </a>
        </div>
        <div className='col col-2 col-sm-6 text-right pr-2 mb-1'>
          <button type='button' onClick={() => changeCookieAgreement('true')}>
            {labels.COOKIE_AGREE}
          </button>
        </div>
        <div className='col col-2 col-sm-6 text-left mb-1'>
          <button type='button' onClick={() => {
            //@ts-ignore
            window['ga-disable-UA-171705652-1'] = true;
            changeCookieAgreement('false')
          }}>
            {labels.COOKIE_DISAGREE}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieManagement;
