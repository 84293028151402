import {graphql, useStaticQuery} from 'gatsby';
import {Link} from 'gatsby';
import React, {useContext} from 'react';
import {FooterType} from 'src/models/Footer.type';
import {LanguageType} from 'src/models/language.type';
import EdmusContext from './application.provider';
import useLabels from '@hooks/useLabels';

const Footer = (props: any) => {
  const {prismic} = useStaticQuery(
    graphql`
      query {
        prismic {
          allLanguagess {
            edges {
              node {
                languages {
                  label
                  language
                  type
                }
                _meta {
                  lang
                }
              }
            }
          }
          allFooters {
            edges {
              node {
                links {
                  facebook
                  instagram
                  linkedin
                }
                texts {
                  contact
                  followUs
                  healthMentions
                  legalNotices
                }
                _meta {
                  lang
                }
              }
            }
          }
        }
      }
    `
  );
  const {labels} = useLabels();
  const {language, changeLanguage} = useContext(EdmusContext);
  const allLanguages: {
    node: {languages: [LanguageType]};
  } = prismic.allLanguagess.edges.find(
    (languages: any) => languages.node._meta.lang.slice(0, 2) === language
  );

  const footer: FooterType = prismic.allFooters.edges.find(
    (footers: any) => footers.node._meta.lang.slice(0, 2) === language
  );

  if (!footer) return null;

  return (
    <footer className='footer container'>
      <div className='width-pourcent-90 p-centered'>
        <div className='columns'>
          <div className='column col-4 col-xl-4 col-lg-4 col-md-12 social-buttons'>
            <div className='mb-2'>{footer.node.texts[0].followUs}</div>
            <div>
              <a href={footer.node.links[0].facebook} target='_blank'>
                <i className='icon-facebook' />
              </a>
              <a href={footer.node.links[0].instagram} target='_blank'>
                <i className='icon-instagram' />
              </a>
              <a href={footer.node.links[0].linkedin} target='_blank'>
                <i className='icon-linkedin' />
              </a>
            </div>
          </div>
          <div className='column col-3 col-xl-3 col-lg-3 col-md-12 margin-adjust'>
            <p>
              <Link to='/newsletter'>{labels.COMMON_NEWSLETTER}</Link>
            </p>
            <p>
              <Link to='/contact'>{footer.node.texts[0].contact}</Link>
            </p>
          </div>

          <div className='column col-3 col-xl-2 col-lg-2 col-md-12'>
            {allLanguages.node.languages.map((l, key) => (
              <p key={key}>
                <button
                  type='button'
                  className={`button-link ${
                    language === l.type ? 'active' : ''
                  }`}
                  onClick={() => changeLanguage(l.type)}
                >
                  {l.label}
                </button>
              </p>
            ))}
          </div>

          <div className='column col-2 col-xl-3 col-lg-3 col-md-12'>
            <Link to='/legal-notices' target='_blank'>
              {footer.node.texts[0].legalNotices}
            </Link>
          </div>
        </div>

        <div className='columns infos'>
          <div className='column col-10 col-xl-9 col-lg-9 col-md-12 health-mentions'>
            {footer.node.texts[0].healthMentions}
          </div>
          <div className='column col-2 col-xl-3 col-lg-3 col-md-12 copyright  margin-adjust'>
            {labels.COMMON_EDMUS_COPYRIGHT}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
