// @ts-nocheck
import React, {useEffect, useRef} from 'react';
import useLabels from '@hooks/useLabels';

const NewsletterModal = (props: any) => {
  const {labels} = useLabels();
  const ref = useRef();

  const onClose = () => {
    props.onClose();
  };

  useEffect(() => {
    const handleClickOutside = e => {
      if (!ref.current.contains(e.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {props.opened && <div className='dialog-overlay'></div>}
      <div
        className={`dialog ${props.opened ? 'opened' : ''} shadow-sm`}
        ref={ref}
      >
        <div className='dialog-header'>
          <div className='d-flex justify-content-end'>
            <button className='btn btn-link' onClick={onClose}>
              <i className='icon icon-cross'></i>
            </button>
          </div>
          <div>
            <h3>{labels.STORES}</h3>
            <div className='sub-line'></div>
            <div style={{margin: '1em 0 0 0', textAlign: 'center'}}>
              <div
                dangerouslySetInnerHTML={{__html: labels.STORE_UNAVAILABLE}}
              ></div>
              <div className='form-group'>
                <a
                  className='btn btn-primary input-group-btn'
                  href={'/edmus-club'}
                >
                  {labels.STORE_SUBSCRIBE}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='dialog-content'></div>
      </div>
    </div>
  );
};

export default NewsletterModal;
