import {useEffect, useContext, useState} from 'react';
import EdmusContext from '@components/application.provider';

const useLabels = () => {
  const LABELS_i18n = require('../i18n/fr'); //DEFAULT LANGUAGE
  const {language} = useContext(EdmusContext);
  const [labels, setLabels] = useState(LABELS_i18n.default);

  useEffect(() => {
    try {
      setLabels(require(`../i18n/${language}`).default);
    } catch (error) {
      console.warn(`${language} not supported`);
    }
  }, [language]);

  return {labels};
};

export default useLabels;
